import * as React from 'react'
import TwitterIconImg from '../images-svg/Twitter_Social_Icon_Rounded_Square_Color.svg'
import GitHubIconImg from '../images-svg/github.svg'
import QiitaIconImg from '../images/qiita_favicon.png'
import NotakaosImg from '../images/twitter_icon_notakaos_800x800.png'

type TopHerorProps = {
  siteName: string
  twitterProfileUrl: string
  qiitaProfileUrl: string
  githubProfileUrl: string
}

export function TopHero(props: TopHerorProps) {
  const { siteName, twitterProfileUrl, qiitaProfileUrl, githubProfileUrl } = props

  return (
    <div className="flex items-center justify-center min-h-[24rem]">
      <div className="flex flex-col items-center justify-between">
        <img src={NotakaosImg} width="128" height="128" className="mb-5 rounded-full" />
        <h1 className="mb-5 font-mono text-4xl font-bold">{siteName}</h1>
        <ul className="flex">
          <li className="mx-1">
            <a href={twitterProfileUrl}>
              <TwitterIconImg alt="Twitter @notakaos" width={24} height={24} className="inline-block" />
            </a>
          </li>
          <li className="mx-1">
            <a href={qiitaProfileUrl}>
              <img src={QiitaIconImg} alt="Qiita @notakaos" width={24} height={24} className="inline-block" />
            </a>
          </li>
          <li className="mx-1">
            <a href={githubProfileUrl}>
              <GitHubIconImg alt="GitHub @notakaos" width={24} height={24} className="inline-block text-black dark:text-white" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
