import { Link, graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import DefaultSiteLayout from '../components/default-site-layout'
import { TopHero } from '../components/top-hero'
import { GlobalFooter } from '../components/global-footer'
import { DefaultHelmet } from '../components/default-helmet'

type HomePageQueryDataType = {
  site: {
    siteMetadata: {
      siteName: string
      siteDescription: string
      siteUrl: string
    }
  }
  allMdx: {
    edges: Array<{
      node: {
        id: string
        excerpt: string
        body: string
        fields: {
          slug?: string
        }
        frontmatter: {
          title: string
          date?: string
          draft?: boolean
        }
      }
    }>
  }
}
type HomePageContextType = { slug: string }
type HomePageProps = PageProps<HomePageQueryDataType, HomePageContextType>

export default function HomePage({ data }: HomePageProps) {
  const { edges: posts } = data.allMdx
  const { siteName, siteDescription, siteUrl } = data.site.siteMetadata

  return (
    <DefaultSiteLayout>
      <div className="HomePage">
        <DefaultHelmet siteName={siteName} siteDescription={siteDescription} siteUrl={siteUrl} ogType="website" />
        <main className="">
          <TopHero
            siteName={siteName}
            twitterProfileUrl={'https://twitter.com/notakaos'}
            qiitaProfileUrl={'https://qiita.com/notakaos'}
            githubProfileUrl={'https://github.com/notakaos'}
          />
          <section className="flex justify-center">
            <ul className="">
              {posts.map(({ node }: { node: any }) => {
                const { title, date, draft } = node.frontmatter
                return (
                  <li
                    key={node.id}
                    className="px-2 mt-4 leading-tight border-l-4 border-gray-200 group dark:border-gray-400 hover:border-green-500"
                  >
                    <Link to={node.fields.slug} className="block group-hover:bg-gray-100 dark:group-hover:bg-gray-800">
                      <span className="text-xs text-gray-400">{date}</span>
                      <br />
                      {draft ? <span className="px-1 mr-1 text-sm text-gray-500 bg-gray-300 rounded-sm">draft</span> : ''}
                      <span className="font-semibold">{title}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </section>
        </main>
        <GlobalFooter siteName={siteName} linkToTop={false} />
      </div>
    </DefaultSiteLayout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    __typename
    site {
      siteMetadata {
        siteName
        siteDescription
        siteUrl
      }
    }
    allMdx(
      filter: { frontmatter: { tags: { in: "blog" } }, fields: { draft: { eq: false } } }
      sort: { order: DESC, fields: slug }
      limit: 1000
    ) {
      edges {
        node {
          id
          excerpt
          body
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD")
            draft
          }
        }
      }
    }
  }
`
